<template>
  <div>
    <NavTools />
    <div class="container-fluid">
      <div class="row page-header-banner page-header-banner-image">
        <div class="col-xs-12">
          <center>
            <h1 class="page-title">REST API Reference</h1>
          </center>
        </div>
      </div>
    </div>

    <div
      v-if="render"
      class="container main-body"
      style="background-color: #fff"
    >
      <div class="row visible-md-block visible-lg-block">
        <div class="col-md-10 col-md-offset-1" style="font-size: 125%">
          <p>
            The data you receive through your InitialView integration in Slate
            will contain the following fields. Please don't hesitate to contact
            us at
            <a href="mailto:contact@initialview.com">contact@initialview.com</a>
            for clarification with any questions!
          </p>

          <table class="table table-bordered slate-docs">
            <tbody>
              <tr>
                <td><code>ETLDate</code></td>
                <td>date that the record became available</td>
              </tr>
              <tr>
                <td><code>uniqueID</code></td>
                <td>unique identifier for the student at your institution</td>
              </tr>
              <tr>
                <td><code>lastName</code></td>
                <td>student's family name</td>
              </tr>
              <tr>
                <td><code>firstName</code></td>
                <td>student's given name</td>
              </tr>
              <tr>
                <td><code>email</code></td>
                <td>email used by the student for applications</td>
              </tr>
              <tr>
                <td><code>dateOfBirth</code></td>
                <td>student's birthday</td>
              </tr>
              <tr>
                <td><code>ivVideo</code></td>
                <td>link to the student's InitialView video</td>
              </tr>
              <tr>
                <td><code>ivSummaryPDF</code></td>
                <td>link to the student's basic information</td>
              </tr>
              <tr>
                <td><code>ivWritingPDF</code></td>
                <td>link to the student's writing sample</td>
              </tr>
              <tr>
                <td><code>star</code></td>
                <td>
                  indicates if the student assigned a
                  <a href="/stars-info" target="_blank">star</a> to your
                  institution
                </td>
              </tr>
              <tr>
                <td><code>syr</code></td>
                <td>
                  indicates if the student assigned their
                  <a
                    href="https://initialview.com/single-yellow-rose"
                    target="_blank"
                    >Single Yellow Rose</a
                  >
                  to your institution
                </td>
              </tr>
              <tr>
                <td><code>recruited</code></td>
                <td>
                  <code>True</code> if the student was recruited to your
                  institution by our
                  <a href="/re-info" target="_blank">recommendation algorithm</a
                  >. If the student was a Tuesday Talks or EPIC participant, the
                  value will be <code>HF</code> for Tuesday Talk participants
                  who gave you a high five and <code>PoL</code> for all other
                  Tuesday Talk participants.
                </td>
              </tr>
              <tr>
                <td><code>ivType</code></td>
                <td>
                  interview type possible values: <code>Undergraduate</code>,
                  <code>Transfer Applicant</code>, <code>Graduate</code>,
                  <code>Gaokao</code>, 
                  <!-- <code>CSAT</code>, -->
                  <code>Summer Program</code>, <code>Writing Sample Only</code>
                </td>
              </tr>
              <tr>
                <td><code>ivEpicVideo</code></td>
                <td>
                  link to the student's
                  <a href="https://initialview.com/epic" target="_blank"
                    >EPIC video</a
                  >
                </td>
              </tr>
              <tr>
                <td><code>ivEPVideo</code></td>
                <td>
                  link to the student's
                  <a
                    href="https://initialview.com/glimpse"
                    target="_blank"
                    >Glimpse video</a
                  >
                </td>
              </tr>
              <tr>
                <td><code>ivEpicType</code></td>
                <td>
                  EPIC type possible values: <code>EPIC</code> (other types to
                  come in the future)
                </td>
              </tr>
              <tr>
                <td><code>ivEPType</code></td>
                <td>
                  Elevator Pitch type possible values:
                  <code>Elevator Pitch</code>, <code>Transfer Applicant</code>,
                  <code>Fly-In</code>
                </td>
              </tr>
              <tr>
                <td><code>ivTtType</code></td>
                <td>
                  Tuesday Talks type possible values:
                  <code>Tuesday Talks</code> (other types to come in the future)
                </td>
              </tr>
              <tr>
                <td><code>cellphone</code></td>
                <td>student's cellphone</td>
              </tr>
              <tr>
                <td><code>preferredName</code></td>
                <td>student's preferred name</td>
              </tr>
              <tr>
                <td><code>genderIdentity</code></td>
                <td>student's gender identity</td>
              </tr>
              <tr>
                <td><code>hsGradYear</code></td>
                <td>student's high school graduation year</td>
              </tr>
              <tr>
                <td><code>collegeEntryYear</code></td>
                <td>student's expected college entry year</td>
              </tr>
              <tr>
                <td><code>schoolName</code></td>
                <td>student's school name</td>
              </tr>
              <tr>
                <td><code>schoolCity</code></td>
                <td>student's school city</td>
              </tr>
              <tr>
                <td><code>schoolCountry</code></td>
                <td>student's school country</td>
              </tr>
              <tr>
                <td><code>schoolCEEB</code></td>
                <td>student's school CEEB code</td>
              </tr>
              <tr>
                <td><code>schoolCurriculum</code></td>
                <td>student's school curriculum</td>
              </tr>
              <tr>
                <td><code>schoolIVCode</code></td>
                <td>(coming soon)</td>
              </tr>
              <tr>
                <td><code>ranking</code></td>
                <td>student's school ranking value (China schools only)</td>
              </tr>
              <tr>
                <td><code>rankingOutOf</code></td>
                <td>
                  number of ranked schools in the student's school ranking group
                  (China schools only)
                </td>
              </tr>
              <tr>
                <td><code>rankingGroup</code></td>
                <td>
                  student's school ranking group, typically a city or region
                  (China schools only)
                </td>
              </tr>
              <tr>
                <td><code>citizenship</code></td>
                <td>student's citizenship</td>
              </tr>
              <tr>
                <td><code>mailingAddress</code></td>
                <td>student's mailing address</td>
              </tr>
              <tr>
                <td><code>acadInterests</code></td>
                <td>
                  student's academic interest possible values (delimited by the
                  character <code>;</code>): <code>Business and Finance</code>;
                  <code>Computer Science, Math, and Engineering</code>;
                  <code>Foreign Languages</code>;
                  <code>Humanities (Literature, Geography, History, etc.)</code
                  >; <code>Lab Sciences (Bio, Chem, Physics, etc.)</code>;
                  <code>Nursing and Medicine</code>;
                  <code>Performing Arts (Dance, Drama, etc.)</code>;
                  <code
                    >Social Sciences (Economics, Political Science, etc.)</code
                  >; <code>Visual Arts (Drawing, Painting, etc.)</code>;
                  <code>I don’t know yet</code>
                </td>
              </tr>
              <tr>
                <td><code>cbo</code></td>
                <td>student's Community Based Organization</td>
              </tr>
              <tr>
                <td><code>role</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>title</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>rating</code></td>
                <td>student's rating in Amelia</td>
              </tr>
              <tr>
                <td><code>comments</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>Review</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>Review2</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>Review3</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>Review4</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>Review5</code></td>
                <td><i>reserved for future use</i></td>
              </tr>
              <tr>
                <td><code>commonAppID</code></td>
                <td>student's Common App ID</td>
              </tr>
              <tr>
                <td><code>coalitionID</code></td>
                <td>student's Coalition ID</td>
              </tr>
              <tr>
                <td><i>institution-specific IDs</i></td>
                <td>values are filled in for relevant institutions</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueResource from "vue-resource";
Vue.use(VueResource);

import NavTools from "@/components/NavTools.vue";

export default {
  props: ["staticaddr", "serveraddr", "ameliaaddr", "lang", "lang_label"],

  components: {
    NavTools,
  },

  data() {
    return {
      render: false,
    };
  },

  mounted() {
    var redirecting = require("@/utils/LoginRequired.js");
    if (!redirecting) {
      this.render = true;
    }
  },
};
</script>

<style lang="scss">
.page-header-banner-image {
  background-image: url(/ivgcs/img/chalkboard-black.jpg);
}

.page-header-banner-row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.page-title {
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.main-body {
  padding-top: 50px;
  padding-bottom: 50px;
}

.slate-docs td {
  font-size: 100%;
}
</style>